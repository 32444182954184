<script setup>
// import {ref,watch,onMounted,computed} from 'vue';
import { storeToRefs } from 'pinia'
import SlideZj from './SlideZj'
//  import Btn from '@/components/Button/Btn.vue'
import TitleZj from '@/components/Title/TitleZj.vue'
import {useFlinksStore} from '../../stores/counter.js'


const store = useFlinksStore()
const {flinksList} = storeToRefs(store);
if(flinksList.value.length===0){
  store.GetFlinksList().then(()=>{})
}


</script>

<template>
  <div class="pages">
    <div class="wrap">
      <div class="slidebox">
        <keep-alive include="slide"><SlideZj></SlideZj></keep-alive>
        <div class="wrap">
         <div class="center">
           <h2>佛山国际商品交易博览会</h2>
           <!-- <h4>产品展+装备材料展 同期举办</h4> -->
           <h4>2024年10月18-22日 </h4>
         </div>
       </div>
   </div>

   <div class="screen5">
    <div class="flexbox">
      <div>
        <h3 class="fs20 mb30">佛山国际商品交易博览会，10月18日开幕</h3>
        <div class="fs16 mb10">佛山制造闻名天下。自明清时期，佛山陶瓷、铸铁远销东南亚。</div>
        <div class="fs16  mb10">当前，佛山以家电为龙头的大家居产业，成就“世界家、佛山造”的美誉。</div>
        <div class="fs16  mb10">佛山的酿酒、酱醋、美食、功夫，亦远近闻名。</div>
        <div class="fs16  mb10">以佛山各领域的头部企业为引领，打造“佛山国际商品交易博览会”正当时候。</div>

        <div class="fs16  mb10 mt20">【时间】2024年10月18-22日</div>
        <div class="fs16  mb10 mt10">【地点】佛山潭洲国际会展中心</div>

        <div class="fs16  mb10 mt20">【展会规模】</div>
        <div class="fs16  mb10">5大展馆，6万㎡展览面积，600+品牌展商</div>
        <div class="fs16  mb10 mt20">【展览范围】</div>
        <div class="fs16  mb10">家用电器、家具、厨具、卫浴、定制家居、家纺用品、庭院户外产品、门窗、墙地柜面材料、陶瓷、石材、粘贴防水材料、铺贴工具等。</div>

        <div class="fs16 mt20">【组织架构】</div>
        <div class="fs16  mb10">指导单位：中国会展经济研究会、广东省贸促会、佛山市人民政府</div>
        <div class="fs16  mb10">主承办：佛山陶联科技发展有限公司</div>
        <div class="fs16  mb10">特别支持：佛山市贸促会、佛山市商务局、三龙湾科技城管委会</div>
        <div class="fs16  mb10">合作支持：佛山市工商业联合会（总商会）、佛山市企业联合会、佛山市企业家协会</div>
      </div>
      <div><img src="../../assets/index-img.jpg" alt=""></div>
    </div>
  </div>


   <div class="screen6 mb40">
      <TitleZj title="支持单位" color="black"></TitleZj>
      <ul>
        <li v-for="(item,index) in flinksList" :key="index">
          <a :href="item.url" target="_bland">
            <img :src="item.picture" :alt="item.title">
          </a>
        </li>
      </ul>
    </div>


      


    </div>
  </div>
</template>

<style scoped>
.pages{width:87.5%; margin-left:12.5%; position:relative; z-index:1; background:#fff; min-height:calc( 100vh - 225px );}

.slidebox{height:43.25vw; position: relative; overflow:hidden;}
.slidebox>.wrap{display:flex; justify-content: center; align-items: center; position: absolute; top:0;bottom:0; left:0; right:0; background:rgba(0,0,0,0.5); color:#fff; z-index:2;}
.slidebox>.wrap h2{font-size: 4.29166667vw; color: #fff; font-weight: 500; margin-bottom: 1.50905vw; text-align: center;}
.slidebox>.wrap h4{font-size:2.41591vw; font-weight: 500; text-align: center; margin-bottom:0.6211vw;}
.slidebox>.wrap h4:nth-of-type(2){margin-bottom:2.5211vw;}
.slidebox>.wrap .btn-box{ display:flex; justify-content: center;}
.slidebox>.wrap .btn-box .main-button{margin:0 0.8vw; width:14vw; height:3.5vw;}
.slidebox>.wrap .btn-box a .main-button{margin:0 1vw; width:17vw; height:3.5vw;}

.screen5 .title-box{margin:3vw 0 1.5vw 6.23428859vw}
.screen5 .flexbox{justify-content: space-between; padding:0 6.23428859vw;}
.screen5 .flexbox>div:nth-of-type(1){width:37.9vw;}
.screen5 .flexbox>div:nth-of-type(1) h3{margin-top:5vw;}
.screen5 .flexbox>div:nth-of-type(1) div{line-height:1.5vw;}
.screen5 .flexbox>div:nth-of-type(2){width:29vw; height:17vw; margin:5vw 0 0 0;}
.screen5 .flexbox>div:nth-of-type(2) img{width:100%; height:100%;}
.screen5 .hack{line-height:1.5vw;}

.screen6 .title-box{margin:3vw 0 1.5vw 6.23428859vw}
.screen6 ul{display:flex; flex-wrap:wrap; margin:1vw 0 1.5vw 6.23428859vw}
.screen6 ul li{margin-right:1vw; margin-bottom:0.6vw;}
.screen6 ul li a{display:block;}
.screen6 ul li a img{height:2.6vw;}
.screen6 ul li a span{display:block; text-align: center; font-size:1vw;}

@media (max-width: 600px) {
  .pages{width: 100vw; margin-top:55px; margin-left:0px; min-height: calc( 100vh - 38vw );}

  .slidebox{height:50vw;}
  .slidebox>.wrap h2{font-size: 5.8vw;  margin-bottom: 1.5vw;}
  .slidebox>.wrap h4{font-size:3.9vw; margin-bottom:1vw;}
  .slidebox>.wrap h4:nth-of-type(2){margin-bottom:3vw;}
  .slidebox>.wrap .btn-box .main-button{margin:0 3vw; width:24vw; height:8vw;}
  .slidebox>.wrap .btn-box a .main-button{margin:0 3vw; width:32vw; height:8vw;}

  .screen5 .flexbox>div:nth-of-type(1){width:92vw;}
  .screen5 .flexbox>div:nth-of-type(1) div{line-height:4.5vw;}
  .screen5 .flexbox>div:nth-of-type(2){display: none;}
  .screen5 .flexbox .mb100{margin-bottom:8vw;}
  .screen5 .fs20{font-size:3.8vw; line-height:3.8vw;}
  .screen5 .fs16{font-size:3.2vw; line-height:3.2vw;}
  .screen5 .hack{line-height:4vw;}

  .screen6 .title-box{margin:8vw 0 4vw 3.23428859vw}
  .screen6 ul{display:flex; flex-wrap:wrap; justify-content: space-around; margin:1vw 1.5vw 1.5vw 2vw; flex-wrap: wrap;}
  .screen6 ul li{margin-right:2vw; margin-bottom:2vw;}
  .screen6 ul li a{display:block;}
  .screen6 ul li a img{height:7vw;}
  .screen6 ul li a span{display:block; text-align: center; font-size:3vw;}
}
</style>

<style type="text/css">
.slidebox>.wrap .btn-box .main-button .text span{font-size:1.5vw; font-weight: bold;}
.slidebox>.wrap .btn-box .main-button .text i{font-size:1.5vw;}
@media (max-width: 991px){
.slidebox>.wrap .btn-box .main-button .text span{font-size:1.8vw;}
.slidebox>.wrap .btn-box .main-button .text i{font-size:1.8vw;}
}
</style>