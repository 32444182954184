<template lang="html">
  <footer>
      <div class="flexbox">
        <div>
          <img src="@/assets/footer-logo.png">
          <h2 class="fs25">佛山国际商品交易博览会</h2>
          <div class="fs16 mt15">UNICERAMICS EXPO</div>
          <div class="fs16 mt12">2024/10/18-22</div>
          <div class="fs16 mt12">佛山（潭洲）国际会展中心</div>
          <div class="link mt35">
            <router-link to="/about">关于我们</router-link>
            <span>/</span>
            <router-link to="/contact">联系我们</router-link>
          </div>
        </div>
        <div class="qrcode-box">
          <div class="qrcode">
            <img src="@/assets/qrcode.jpg" alt="视频号">
            <div class="fs16 mt10">视频号</div>
          </div>
          <div class="qrcode" style="margin-left:1vw;">
            <img src="@/assets/qrcode-wx.jpg" alt="微信">
            <div class="fs16 mt10">微信</div>
          </div>
        </div>
      
      </div>
      <div class="center mt60 pc">
        Uniceramics Expo <a target="_bland" href="https://beian.miit.gov.cn/">粤ICP备20006720号</a>
        <span>佛山陶联科技发展有限公司</span>
      </div>
      <!-- <div class="mobile-footer">
        <img src="@/assets/footer.jpg" />
      </div> -->
  </footer>
</template>

<script>
export default {
    name:'FooterBar',
    data() {
        return {}
    },
    // computed:{
    //   lang(){return this.$store.state.index.lang}
    // },
}
</script>

<style scoped>
footer{margin-left:12.5vw; overflow: hidden; box-sizing:border-box; height:17vw; padding:3.2vw 5.3vw 3.5vw 4.3vw; background: #ff0066; color:#fff; }
footer .flexbox{display:flex; justify-content: space-between;}
footer .flexbox>div:nth-of-type(1){padding-left:8.42vw; position: relative;}
footer .flexbox>div:nth-of-type(1) img{width:7.895vw; height:7.368vw; position:absolute; top:0; left:0;}
footer .flexbox>div:nth-of-type(1) .mt12{margin-top:0.65vw;}
footer .flexbox>div:nth-of-type(1) .link>a{color:#ddd;}
footer .flexbox>div:nth-of-type(1) .link>span{margin:0 0.8vw;}
footer .flexbox>div:nth-of-type(1) .link>a:hover{text-decoration: underline;}

footer .flexbox>div.qrcode-box{display:flex;}
footer .flexbox>div.qrcode-box.en .qrcode{width:7vw;}
footer .flexbox>div:nth-of-type(2){text-align:center;}
footer .flexbox>div:nth-of-type(2) img{width:6.4vw; height:6.4vw;}
footer>.center{text-align:center; color:#fbbebb;}
footer .mobile-footer{display:none;}
footer>.center>span{margin-left:5px;}
footer>.center>a{text-decoration: underline;color:#fbbebb;}
footer>.center>a:hover{color:blue;}
@media (max-width: 600px){

footer{overflow: hidden; margin-left:0vw; height:38vw; padding:6.9vw 2.3vw 3.5vw 2.3vw;}
footer .flexbox>div.qrcode-box.en .qrcode{width:15vw;}
footer .flexbox>div:nth-of-type(1){padding-left:0;}
footer .flexbox>div:nth-of-type(1) img{display:none;}
footer .flexbox>div:nth-of-type(1) .mt12{margin-top:1.8vw;}
footer .flexbox>div:nth-of-type(1) .link{margin-top:4vw;}
footer .flexbox>div:nth-of-type(2) img{width:14.5vw; height:14.5vw;}
footer>.center{margin-top:5vw;}
footer>.center>span{margin-left:10px;}
}
</style>

