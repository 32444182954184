<template>
    <header class="hello">
      <div class="sidebar-wrapper">
         <div class="logo"><img src="../assets/header-logo.png" alt="logo"></div>
         <ul>
            <li class="nav-item" :class="{hover:hoverIndex===0}" @click='sideBarClick(0)' @mouseenter="visible(0)" @mouseleave="invisible(0)">
               <router-link class="nav-box item-box" to="/index">
                  <span>首页</span>
              </router-link>
            </li>
      
            <li class="nav-item" :class="{hover:hoverIndex===1}" @click='sideBarClick(1)' @mouseenter="visible(1)" @mouseleave="invisible(1)">
               <router-link class="nav-box item-box" to="/about">
                  <span>关于我们</span>
              </router-link>
            </li>

            <li class="nav-item" :class="{hover:hoverIndex===2}" @click='sideBarClick(2)' @mouseenter="visible(2)" @mouseleave="invisible(2)">
               <router-link class="nav-box item-box" to="/news">
                  <span>展会动态</span>
              </router-link>
            </li>

            <li class="nav-item" :class="{hover:hoverIndex===1}" @click='sideBarClick(3)' @mouseenter="visible(1)" @mouseleave="invisible(3)">
               <router-link class="nav-box item-box" to="/contact">
                  <span>联系我们</span>
              </router-link>
            </li>

            <!-- <li class="nav-item" :class="{hover:hoverIndex===5}" @click='sideBarClick(5)' @mouseenter="visible(5)" @mouseleave="invisible(5)">
               <a class="nav-box item-box" href="http://www.uniceramics.com.cn/live2024/playback.html" target="_bland">
                  <span>直播回放</span>
               </a>
               <div class="sub-nav-wrapper">
                  <div>
                     <div class="cover"><img src="https://reg.uniceramics.com.cn/live/images/poster18.jpg?temp=0630" alt=""></div>
                     <ul class="sub-nav">
                        <li class="sub-nav-item" @click="subMenuClick">
                           <a href="http://www.uniceramics.com.cn/live2024/playback.html" target="_bland" class="a">2024直播精彩回放</a>
                        </li>
                        <li class="sub-nav-item" @click="subMenuClick">
                           <a href="http://www.uniceramics.com.cn/live2023/playback.html" target="_bland" class="a">2023直播精彩回放</a>
                        </li>
                        <li class="sub-nav-item" @click="subMenuClick">
                           <a href="http://www.uniceramics.com.cn/live/playback2021.php" target="_bland" class="a">2021直播精彩回放</a>
                        </li>
                        <li class="sub-nav-item" @click="subMenuClick">
                           <a href="http://www.uniceramics.com.cn/live/playback.php" target="_bland" class="a">2020直播精彩回放</a>
                        </li>
                     </ul>
                  </div>
               </div>
            </li> -->


      
         </ul>

      </div>
    </header>
</template>
  
<script setup>
import {ref} from 'vue'
// import { storeToRefs } from 'pinia'
import { RouterLink} from 'vue-router'

const hoverIndex = ref(0);


const sideBarClick = (index)=>{
   hoverIndex.value = index;
}

const visible = (index)=>{
   hoverIndex.value = index;
}

const invisible = ()=>{
   hoverIndex.value = '';
}
const subMenuClick = ()=>{
   hoverIndex.value = '';
}

</script>
  

<style scoped>
header{width: 12.5%; height: 100vh;position: fixed; z-index: 990; box-shadow: 1px 1px 4px #ccc; background:#fff;}
header .logo{text-align: center;}
header .logo img{width: 85%; margin: auto; margin-top: 1.5vw;}
header .sidebar-wrapper{width: 12.5%; height: 100vh; position: fixed; z-index: 990;}
header .sidebar-wrapper:after{
    content: " ";
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: #fff;
    z-index: -1;
    -webkit-box-shadow: 0 0 5px 0 #99a0a7;
    box-shadow: 0 0 5px 0 #99a0a7;
}


a:hover{color: #ff0066;}
ul{width: 100%;color: #141414;margin-top: 10px; list-style: none;}
ul li a{color:#333; display: flex;justify-content: center;align-items: center;padding: .625vw .52083333vw; margin: 0.9vw 0; cursor: pointer;text-align: center;position: relative;font-size:0.85vw;}
ul li a.router-link-active{color:#ff0066}
ul li a:hover:after, ul li a.active:after, ul li a.router-link-active:after
{content: "";display: block;position: absolute;top: 50%;margin-top: .5px;right: 0;
width: 30px; height: 1px;background-color: #ff0066;}

.nav-item.hover .sub-nav-wrapper {
    left: 100%;
}
/*二级目录*/
.sub-nav-wrapper {
    width: 80%;
    height: 100vh;
    position: absolute;
    top: 0;
    left: -20%;
    background: #fff;
    z-index: -2;
    -webkit-box-shadow: 0 0 5px 0 #99a0a7;
    box-shadow: 0 0 5px 0 #99a0a7;
    -webkit-transition: left .4s cubic-bezier(.645,.045,.355,1) .1s;
    transition: left .4s cubic-bezier(.645,.045,.355,1) .1s;
}
.sub-nav-wrapper>div {height: 100vh;width: 100%;overflow: hidden;}
.sub-nav-wrapper>div .cover {width: 8.125vw;margin: .78125vw auto 2vw;}
.sub-nav-wrapper>div .cover img {width: 100%;}
.sub-nav-wrapper>div .sub-nav .sub-nav-item {overflow: hidden;}
.sub-nav-wrapper>div .sub-nav .sub-nav-item>.a {display: block;text-align: center;padding: .625vw .52083333vw;margin: .67708333vw 0;cursor: pointer;position: relative;font-size:0.85vw;}
.sub-nav-wrapper>div .sub-nav .sub-nav-item>a.a:hover ,.sub-nav .router-link-active{background: #f2f3f7; color:#ff0066;}

@media (max-width: 600px){
   header{display: none}
}

</style>