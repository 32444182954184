<template>
  <div class="title-box" :class="[color]">
    <span>{{title}}</span>
  </div>
</template>

<script>
export default {
  name: 'TitleZj',
  props:{
    title:{type:String,default:''},
    color:{type:String,default:'white'},
  }
}
</script>

<style scoped>
.title-box{height:1.42255vw; line-height:1.42255vw; font-size:0; color:#fff; display:flex; align-items: center;}
.title-box>span{font-size:1.422vw; }
.title-box:before{content:''; display: inline-block; margin-right:5px; width:1.15vw; height:1.15vw; border-radius:1.42255vw; background:#fff;}

.title-box.black{color:#000;}
.title-box.black:before{background: #000;}

@media (max-width: 991px){
.title-box{height:4.5vw; line-height:4.5vw;}
.title-box>span{font-size:4.5vw; }
.title-box:before{width:4.2vw; height:4.2vw; border-radius:4.2vw; margin-right:8px;}
}
</style>