import './assets/main.css'
import { createApp} from 'vue'
import { createPinia } from 'pinia'
import App from '@/App.vue'
import router from '@/router'

const app = createApp(App)

app.use(createPinia())
app.use(router)
app.mount('#app')

setTimeout(()=>{
    document.dispatchEvent(new Event('custom-render-trigger'))
},2000)

