import request from './request'

// 轮播图
export function getBanner(params={}) {
  return request({
    url: 'banner',
    method: 'get',
    params
  })
}

//友情链接
export function getFlinks(params) {
  return request({
    url: 'links/2',
    method: 'get',
    params
  })
}

//支持单位
export function getSupport(params) {
  return request({
    url: 'links/1',
    method: 'get',
    params
  })
}

//展会介绍
export function getDetail() {
  return request({
    url: 'about',
    method: 'get'
  })
}

//新闻列表
export function getNewsList(params={}) {
  return request({
    url: 'news',
    method: 'get',
    params
  })
}

//新闻详情
export function getNewsDetail(id) {
  return request({
    url: 'news/'+id,
    method: 'get'
  })
}


// export function getRankingList(params) {
//   return request({
//     url: '/ranking/list',
//     method: 'post',
//     data: params
//   })
// }

// export function getCompanyById(id) {
//   return request({
//     url: '/company/'+id,
//     method: 'get',
//   })
// }

// export function getNewsList(params) {
//   return request({
//     url: '/news/list',
//     method: 'post',
//     data: params
//   })
// }

// export function getNewsById(id) {
//   return request({
//     url: '/news/'+id,
//     method: 'get',
//   })
// }

// // 6. 企业新闻列表
// export function getCompanyNewsList(params) {
//   return request({
//     url: '/company/news/list',
//     method: 'post',
//     data: params
//   })
// }

// export function getCompanyNewsById(id) {
//   return request({
//     url: '/company/news/'+id,
//     method: 'get',
//   })
// }




