import { createRouter, createWebHistory } from 'vue-router'
import IndexView from '../views/index/IndexView.vue'
// import AboutView from '../views/index/IndexView.vue'
// import NewsView from '../views/news/NewsView.vue'



const router = createRouter({
  history: createWebHistory(),
  // scrollBehavior (to, from, savedPosition) {
  scrollBehavior () {
    return { top: 0 }
  },
  routes: [
    {
      path: '/',
      redirect: '/index'
    },
    {
      path: '/index',
      name: 'index',
      component: IndexView
      // component: () => import('../views/IndexView.vue')
    },
    {
      path: '/about',
      name: 'about',
      component: () => import('../views/about/AboutView.vue')
    },
    {
      path: '/contact',
      name: 'contact',
      component: () => import('../views/contact/ContactView.vue')
    },
    {
      path: '/news',
      name: 'news',
      // component: NewsView
      component: () => import('../views/news/NewsView.vue')
    },
    {
      path: '/show/:id',
      name: 'show',
      // component: DetailView
      component: () => import('../views/show/ShowView.vue')
    },
    
  ]
})

export default router
