import axios from 'axios'
// import vue from '../main.js'
// import store from '@/store'

// create an axios instance
const service = axios.create({
  // baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  baseURL:'https://adcn.foshantradefair.com/api/v1/',
  // baseURL:'http://139.9.183.248:8087/api/v1/',
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 5000 // request timeout
})
// console.log(process.env.VUE_APP_BASE_API);
// request interceptor request拦截器
service.interceptors.request.use(
  config => {
    // do something before request is sent

    // if (store.getters.token) {
      // console.log("request拦截器");
      // 让每个请求携带token--['X-Token']为自定义key
      // let each request carry token
      // ['X-Token'] is a custom headers key
      // please modify it according to the actual situation
      // config.headers['X-Token'] = getToken()
    // }
    return config
  },
  error => {
    // do something with request error
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  response => {
    const res = response.data
    if(response.status===200){
        return res
    }else{
        console.log('error', res)
    }
  },
  error => {
    console.log('err' , error)
    return Promise.reject(error)
  }
)

export default service
