import { ref } from 'vue'
import { defineStore } from 'pinia'
import {getBanner,getFlinks} from '@/api/index';


// export const useCounterStore = defineStore('counter', () => {
//   const year =  ref(localStorage.year || '2022');
//   function setYear(y){
//     ranklist.value = [];
//     year.value = y
//     localStorage.setItem("year",y)
//   }

//   const typeid =  ref(localStorage.typeid*1 || 1);
//   function setTypeId(y){
//     ranklist.value = [];
//     typeid.value = y
//     localStorage.setItem("typeid",y)
//   }

//   //排行榜相关数据
//   const ranklist = ref([]);
//   async function GetRankingList(){
//     try {
//       let res = await getRankingList({ranking_year:year.value, type_id:typeid.value})
//       ranklist.value = res.data;
//       return res.data;
//     } catch (error) {
//       console.log(error)
//       return error
//     }
//   }

//   return { year,setYear,typeid,setTypeId,ranklist,GetRankingList }
// })


export const useBannerStore = defineStore('banner', () => {
  const bannerList = ref([]);
  async function GetBannerList(){
    try {
      let res = await getBanner({})
      // console.log(res)
      bannerList.value = res;
      return res;
    } catch (error) {
      console.log(error)
      return error
    }
  }
  return {bannerList,GetBannerList}
})

export const useFlinksStore = defineStore('flinks', () => {
  const flinksList = ref([]);
  async function GetFlinksList(){
    try {
      let res = await getFlinks({})
      // console.log(res)
      flinksList.value = res;
      return res;
    } catch (error) {
      console.log(error)
      return error
    }
  }
  return {flinksList,GetFlinksList}
})

