<template lang="html">
    <header :class="{active: isActive}">
      <div class="logo-wrap">
        <div class="title">潭洲陶瓷展</div>
        <router-link to="/index" class="logo">
          <img src="@/assets/header-logo.png" alt="">
        </router-link>
        <div class="menu-button" :class="{active: isActive}" @click="openNav"><div class="bar"></div> <div class="bar"></div> <div class="bar"></div></div>
      </div>
      <div class="nav-wrap zh" @click="isActive=false">
  
        <ul class="nav">
         <li><router-link class="a" to="/index">首页</router-link></li>
         <li><router-link class="a" to="/about">关于我们</router-link></li>
         <li><router-link class="a" to="/news"><span>展会动态</span></router-link></li>

         <li><router-link class="a" to="/contact"><span>联系我们</span></router-link></li>

         <!-- <li class="i18n">
            <a class="a" href="https://www.uniceramicsexpo.com/">
              <span>ENGLISH</span>
            </a>
         </li> -->
  
        </ul>
        
      </div>
    </header>
  </template>
  
  <script setup>
import {ref} from 'vue';
const isActive = ref(false);
// const hoverIndex = ref('');
// const subNavShowIndex = ref('');


const openNav = ()=>{
    isActive.value = !isActive.value
}
// const closeNav = ()=>{
//     isActive.value = false;
// }
// const openSubmenu = (index)=>{
//    if(subNavShowIndex.value===index){
//       subNavShowIndex.value='';
//    }else{
//       subNavShowIndex.value=index;
//    }
// }



//   export default {
//       data() {
//           return {
//               isActive:false,
//               hoverIndex:'',
//               showRouter:[],
//               subNavShowIndex:'',
//               subMenuHeight:0,
//               title:''
//           }
//       },
//     //   created(){
//     //     const routes = this.routes
//     //     let showRouter = [];
//     //     this.routes.forEach((item)=>{
//     //       if(!!!item.hidden){
//     //         showRouter.push(item)
//     //       }
//     //     })
//     //     this.showRouter=showRouter;
        
//     //   },
//       computed: {
//         routes() {
//           return this.$router.options.routes
//         },
//         //根据二级标签长度计算二级导航高度
//         subMenuLength(){
//           return (arr)=>{
//             let num = 0;
//             arr.forEach((item)=>{
//               !item.hidden&&num++; //过滤隐藏页面
//             })
//             return num;
//           }
//         },
//       },
//       methods:{
//         openNav(){
//           this.isActive = !this.isActive;
//         },
//         closeNav(title){
//           this.isActive = false;
//           this.title=title;
//         },
//         openSubmenu(index){
//           if(this.subNavShowIndex===index){
//             this.subNavShowIndex='';
//           }else{
//             this.subNavShowIndex=index;
//           }
//         },
//         tips(){
//           // this.$message('暂未开放，敬请期待！');
//         },
//         changeLang(){
//         //   let locale = localStorage.getItem('locale');
//         //   let n = ''
//         //   if(locale==='zh'){
//         //     n = 'en'
//         //     this.$store.commit('index/setLang','en');
//         //   }else{
//         //     n = 'zh'
//         //     this.$store.commit('index/setLang','zh');
//         //   }
//         //   this.$i18n.locale = n;  //修改i18n的locale值，
//         //   localStorage.setItem('locale',n);  //存入本地的locale值
//         //   this.$router.go(0);
//         }
//       }
//   }
  </script>
  
  <style scoped>
  header{display:none;}
  
  @media (max-width: 991px){
  header{display:block; height: 55px; position: fixed;width: 100%; top: 0;left: 0; z-index: 3000;}
  header .logo-wrap{height: 55px; display:flex; justify-content: space-between; align-items: center; position: relative; z-index: 10; background:rgba(255,255,255,0.95); padding:0 5px; box-shadow: 0 0 6px 0 #bec5cc; font-size:16px;}
  header .logo-wrap .logo{position: relative; z-index:10;}
  header .logo-wrap .logo img{width:45px; height:45px;}
  header .logo-wrap .title{height:55px; line-height:55px; position: absolute; z-index:9; left:0; right:0; text-align:center;}
  
  
  .menu-button {width: 32px; height: 22px;;cursor: pointer; position: relative; z-index:10;}
  .menu-button .bar { position: absolute; width: 100%; height: 2px; left: 0; background: #ff0066; -webkit-transition: all .5s;transition: all .5s;}
  .menu-button .bar:first-child { top: 0;}
  .menu-button .bar:nth-child(2){top: calc(50% - 1px);}
  .menu-button .bar:nth-child(3){ bottom: 0;}
  .menu-button.active .bar:first-child {top: 50%; transform: rotate(45deg) translateY(-70%);}
  .menu-button.active .bar:nth-child(2) {opacity: 0;}
  .menu-button.active .bar:nth-child(3) {bottom: 50%; transform: rotate(-45deg) translateY(80%);}
  
  
  .nav-wrap{position: absolute; z-index: 9;width: 100%; height: calc(100vh - 55px);top:100%;left:0; overflow-y: auto; visibility: hidden; background:rgba(255,255,255,0); transition-duration: 300ms;}
  .nav-wrap ul.nav{transform: translate3d(0,-100%,0); transition-duration: 500ms; background:#fff;}
  .nav-wrap ul.nav>li:nth-of-type(1){padding-top:10px;}
  .nav-wrap ul.nav>li>.a{display: block; height:50px; line-height:50px;text-align:center; font-size:18px; color:#111; font-weight:300; border-bottom: 0.5px solid #eee}
  .nav-wrap ul.nav>li>.a.router-link-active{color:#ff0066;}
  .nav-wrap ul.nav>li>.a i{transition-duration: 300ms; margin-left:5px;}
  .nav-wrap ul.nav>li>.a.show i{transform: rotate(90deg);}
  .nav-wrap .sub-nav{background:#f6f6f6;  overflow: hidden; transition-duration: 300ms;} 
  .nav-wrap .sub-nav.hide{height:0!important;}
  .nav-wrap .sub-nav>li>.a{display:block; height:45px; line-height:45px;text-align:center; font-size:16px; color:#666;}
  .nav-wrap .sub-nav>li>a.router-link-active{color:#ff0066;}
  
  
  header.active .nav-wrap{background:rgba(255,255,255,.99); visibility: visible;}
  header.active .nav-wrap .nav{transform: translate3d(0,0,0);}
  
  }
  </style>
  
  