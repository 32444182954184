<template>
  <div>
    <div class="swiper-container bannerSwiper">
        <div class="swiper-wrapper">
          <div class="swiper-slide" v-for="(item,index) in bannerList" :key="index">
            <img :src="item.picture" :alt="item.title">
        </div>
      </div>
	</div> 


  </div>
</template>

<script setup>
import { onMounted,nextTick,ref} from 'vue';
import { storeToRefs } from 'pinia'
import {useBannerStore} from '../../stores/counter.js'

import Swiper from 'swiper'
import 'swiper/css/swiper.min.css';


const store = useBannerStore()
const swiper = ref(null);

const {bannerList} = storeToRefs(store) 

// console.log(bannerList.value)
if(bannerList.value.length===0){
  store.GetBannerList().then(()=>{
    swiperInit()
  })
}

onMounted(()=>{
  nextTick(()=>{
    if(bannerList.value.length>0){
      !swiper.value&&swiperInit();
    }
  })
})

const swiperInit = ()=>{
  // console.log("swiper banner init")
  new Swiper('.bannerSwiper', {
        slidesPerView: 'auto',
        spaceBetween: 30,
        speed:500,
        effect:'fade',
        autoplay: {
          delay: 2000,
          // stopOnLastSlide: false,
          disableOnInteraction: true,
        },
        loop:true,
  });
}


</script>

<style>
.bannerSwiper{width:87.5vw; height:49vw; position: relative; top:0; z-index:1;}
.bannerSwiper img{width:87.5vw; height:49vw;}

.swiper-slide img{
  width:100%;
  height:auto;
  min-height:100%;
  transition:1s linear 2s;
  transform:scale(1.1,1.1);
}
.swiper-slide-active img,.swiper-slide-duplicate-active img{
  transition:5s linear;
  transform:scale(1,1);
}

@media (max-width: 600px){
  .bannerSwiper{width:100vw; height:56vw; position: relative; top:0; z-index:1;}
  .bannerSwiper img{width:100vw; height:56vw;}
}
</style>

